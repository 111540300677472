import React, { useState } from 'react';
import siteLogo from '../../assets/siteLogo.png';

import AppPicker from '../AppPicker/AppPicker';
import BackButton from '../BackButton/BackButton';
import PlatformPicker from '../PlatformPicker/PlatformPicker';
import TutorialView from '../TutorialView/TutorialView';

import styles from './wrapper.module.css';

const Head = ({ heading }) => {
    return (
        <div className={styles.head}>
            <img src={siteLogo} alt={"GLDS company logo"} />
            <div className={styles.heading}>
                <h1>{heading}</h1>
            </div>
        </div>
    )
}

const Details = () => {
    return (
        <div className={styles.details}>
            <p>
                To see tutorials and walkthroughs for out products and services, select a service below to get started.
            </p>
        </div>
    )
}

const Instruction = ({ instruction }) => {
    return (
        <div className={styles.instruction}>
            <h1>
                {instruction}
            </h1>
        </div>
    )
}

const Body = ({ children }) => {
    return (
        <div className={styles.body}>
            <div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

function Wrapper(props) {
    const [appPickerView, setAppPickerView] = useState("");
    const [platformPickerView,setPlatformPickerView] = useState("right");
    const [tutorialViewView,setTutorialViewView] = useState("right");
    const [isHomeView, setIsHomeView] = useState(true);
    const [platformType,setPlatformType] = useState("");

    const [currentView, setCurrentView] = useState('app');

    const [heading, setHeading] = useState("Welcome!");
    const [showDetails, setShowDetails] = useState(true);
    const [instruction, setInstruction] = useState("Select a service");

    const handleAppPickerClick = (e) => {
        console.log(e);
        debugger;
        let button = e.target.closest('button');
        if (!button) return;
        let btnVal = button.value;
        
        switch (btnVal) {
            case "report_app":
                setAppPickerView("left");
                setPlatformPickerView("");                
                setIsHomeView(false)                                
                setInstruction("Select a platform");
                console.log('Report app chosen');
                break;
        
            default:
                break;
        }
        setCurrentView('platform');     
    }

    const handlePlatformPickerClick = (e) => {
        console.log(e);
        debugger;
        let button = e.target.closest('button');
        if (!button) return;
        let btnVal = button.value;
        
        switch (btnVal) {
            case "android":
                setPlatformPickerView("left");
                setTutorialViewView("");
                setPlatformType('android');
                setInstruction("Android");
                break;
            case "ios":
                setPlatformPickerView("left");
                setTutorialViewView("");
                setPlatformType('ios');
                setInstruction("iPhone");
                break;
        
            default:
                break;
        }
        setCurrentView('tutorial');
    }

    const handleBackButtonClick = (e) => {
        console.log(e);
        console.log('Back button click event');

        switch (currentView) {
            case "app":                
                break;
            case "platform":
                setPlatformPickerView("right");
                setAppPickerView("");                
                setIsHomeView(true);
                setInstruction("Select a service");
                setCurrentView('app');
                break;
            case "tutorial":
                setTutorialViewView("right");
                setPlatformPickerView("");
                setInstruction("Select a platform");
                setCurrentView('platform');
                break;
        
            default:
                break;
        }
    }

    return (
        <div className={styles.wrapper}>
            {!isHomeView && <BackButton onClick={handleBackButtonClick} />}
            <Head heading={heading} />
            <Details visible={showDetails} />
            <Instruction instruction={instruction} />
            <Body>                
                <AppPicker position={appPickerView} onClick={handleAppPickerClick} />
                <PlatformPicker position={platformPickerView} onClick={handlePlatformPickerClick} />
                <TutorialView type={platformType} position={tutorialViewView} />
            </Body>
        </div>
    );
}

export default Wrapper;