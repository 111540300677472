import React from 'react';

import styles from './videoView.module.css';

function VideoView({ heading,link }) {
    return (
        <div className={styles.video_view}>
            <div>
                <div className={styles.head}>
                    <h1>{heading}</h1>
                </div>
                <div>
                    <iframe style={{borderRadius:"10px"}} height="315" src={link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default VideoView;