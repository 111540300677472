import React from 'react';

import VideoView from './components/VideoView/VideoView';

import styles from './tutorialView.module.css';

const platformLinks = {
    ios: "https://www.youtube.com/embed/DtJ53LAI6mE",
    android: "https://www.youtube.com/embed/7zPW_oICMyY"
}


function TutorialView({ position,type }) {
    return (
        <div className={`${styles.tutorial_view} ${position === "" ? "" : styles[position]}`}>
            <div className={styles.option_wrapper}>
                <VideoView heading={"Installation"} link={platformLinks[type]} />
                <VideoView heading={"Walkthrough"} link={"https://www.youtube.com/embed/arausL8v4Dw"} />

                {/* <h1>Installation</h1>
                <iframe style={{borderRadius:"10px"}} height="315" src="https://www.youtube.com/embed/7zPW_oICMyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                {/* <h1>Installation</h1>
                <iframe style={{borderRadius:"10px"}} height="315" src="https://www.youtube.com/embed/DtJ53LAI6mE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <h1>Walkthrough</h1>
                <iframe style={{borderRadius:"10px"}} height="315" src="https://www.youtube.com/embed/arausL8v4Dw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>
        </div>
    );
}

export default TutorialView;