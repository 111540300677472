import React from 'react';

import Wrapper from './components/wrapper/Wrapper';

import styles from './app.module.css';

function App() {
  return (
    <div className={styles.app}>
        <Wrapper />
    </div>
  );
}

export default App;
