import React from 'react';
import pocketReportLogo from '../../assets/pocketReportBtnLogo.png';

import styles from './appPicker.module.css';

function AppPicker({ position,onClick }) {
    return (
        <div className={`${styles.app_picker} ${position === "" ? "" : styles[position]}`}>
            <div className={styles.option_wrapper}>
                <button onClick={onClick} value={"report_app"}>
                    <img src={pocketReportLogo} alt={"Logo for pocket reports"} />
                </button>
            </div>            
        </div>
    );
}

export default AppPicker;